<template>
    <div class="loading" :style="{height: `${height}vw`}">
        <Spin fix>
            <Icon type="ios-loading" size=38 class="demo-spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    data () {
        return {
        }
    },
    props: {
        height: String
    }
}
</script>

<style scoped>
.loading{
    width: 100%;
    height: 20vw;
    position: relative;
}
.demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}
</style>
