<template>
    <div class="warp">
        <div class="pass-through">
            <p class="title">一机在手，百城通行</p>
            <p class="desc">支持40余张交通卡，覆盖全国300+城市；其中京津冀互联互通卡永久免费开卡，支持全国300+城市刷卡使用</p>
            <div class="card-con">
                <Loading v-if="!cardList.length" height="50"/>
                <ul v-else>
                    <li v-for="(card, index) in cardList" :key="index">
                        <img :src="card.imgUrl" alt="">
                        <p>{{card.name}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Loading from "@/components/Loading.vue";
    export default {
        name: 'Home',
        data () {
            return {
                cardList: [],
            }
        },
        components: {
            Loading
        },
        async mounted() {
            const data = await axios.get(`https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/data/buscardList.json?num=${Math.random()}`)
            this.cardList = data.data
        }
    }
</script>

<style scoped>
.warp .title{
    text-align: center;
    margin: 5vw 0 .5vw;
    font-size: 2vw;
    font-weight: 400;
}
.warp .desc{
    text-align: center;
    font-size: .9vw;
    color: #666;
}
.pass-through .card-con{
    width: 74%;
    margin: 3vw auto;
}
.pass-through .card-con ul{
    display: flex;
    flex-wrap: wrap;
}
.pass-through .card-con ul li{
    text-align: center;
    padding: 1.5vw 0vw;
    box-sizing: border-box;
    width: 25%;
}
.pass-through .card-con ul li img{
    width: 14vw;
}
.pass-through .card-con ul li p{
    padding: 0.6vw 0;
    font-size: 1.2vw;
}
</style>